import React from 'react';
import './styles.css';
import ScrollAnimation from 'react-animate-on-scroll';

const AboutBlock3 = () => {
    return (
        <>
            <div className='about-block-3' style={{overflow: "hidden", maxWidth: "100%"}}>
                <ScrollAnimation animateIn="fadeInRight" animateOut='fadeOutRight'>
                    <div className='about-block-3-top'/>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" animateOut='fadeOutRight'>
                    <div className='about-circle-top'/>
                </ScrollAnimation>
                
                
                <div className='p-5' style={{paddingTop: "100px"}}>
                    <div className='row mt-5 mb-5'>
                        <div className='col-12 col-xs-12 col-sm-6'>
                            <ScrollAnimation animateIn="fadeInLeft" animateOut='fadeOutLeft'>
                                <img src="/img/banner/opera-house.jpg" style={{width: "100%", margin: "0px auto", borderRadius: "15px", filter: "drop-shadow(5px 5px 5px rgba(0,0,0,0.2))", position: "relative", left: "-50px"}}></img>
                            </ScrollAnimation>
                        </div>
                        <div className='col-12 col-xs-12 col-sm-6'>
                            <ScrollAnimation animateIn="fadeInRight" animateOut='fadeOutRight'>
                                <h2 style={{fontWeight: "300"}} className='fs-section-header'>Our Vision</h2>
                                <p className='lead' style={{fontSize: "1.3em"}}>
                                    We are the partner of choice for Australian businesses and advisers. 
                                    We take the time to understand our clients businesses and are committed to bringing the best in service, experience and technologies through our best in class team.
                                </p>
                                <a className='btn btn-sm btn-fill mb-5' href='/services'>Go To Our Services</a>
                            </ScrollAnimation>
                        </div>
                        <div className='col-12' style={{minHeight: "45px"}}>
                            
                        </div>
                       
                        <div className='col-12 col-xs-12 col-sm-6' style={{marginBottom: "30px"}}>
                        <ScrollAnimation animateIn="fadeInLeft" animateOut='fadeOutLeft'>
                            <h2 style={{fontWeight: "300"}} className='fs-section-header'>Finstead Partners</h2>
                            <p className='lead' style={{fontSize: "1.3em"}}>
                                Behind every successful business is an amazing network of partners, clients and supporters. Finstead is one of Australia's fastest growing financial service businesses, relying heavily on word of mouth and a trusted network of referral partners.
                                We collaborate closely with accountants, financial advisors, mortgage brokers and financial planners to help their clients grow.
                            </p>
                            <p className='lead' style={{fontSize: "1.3em"}}>
                            Becoming a Finstead Partner is a simple process. Contact us at <a href="mailto:partners@finstead.com.au">partners@finstead.com.au</a>
                            </p>
                            <a className='btn btn-sm btn-fill mb-5' href='/contact'>Get In Touch</a>
                        </ScrollAnimation>
                        </div>
                        <div className='col-12 col-xs-12 col-sm-6'>
                        <ScrollAnimation animateIn="fadeInRight" animateOut='fadeOutRight'>
                            <img src="/img/banner/man-with-pen.jpg" style={{width: "100%", margin: "auto 0px", borderRadius: "15px", filter: "drop-shadow(5px 5px 5px rgba(0,0,0,0.2))", position: "relative", left: "50px"}}></img>
                        </ScrollAnimation>
                        </div>
                    </div>
                </div>
                
                <br/>
                <br/>
                <br/>
                <ScrollAnimation animateIn="fadeInLeft" animateOut='fadeOutLeft'>
                    <div className='about-block-3-bottom'/>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" animateOut='fadeOutLeft'>
                    <div className='about-circle-bottom'/>
                </ScrollAnimation>
            </div>
        </> 
    )
}

export default AboutBlock3;