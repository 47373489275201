import React from 'react';
import { Parallax } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';

const AboutBlock1Paralax = () => {
    return (
        <div style={{width: "80%", margin: "auto auto", borderRadius: "50px", marginBottom: "150px", marginTop: "150px"}}>
            <Parallax strength={150} blur={0} bgImage="/img/banner/fs-bg1.jpg" bgImageAlt="Sydney Opera House" bgImageStyle={{ objectFit: 'cover'}} style={{borderRadius: "30px", objectFit: "cover"}}>
                <section className="section-space-default-less30 " >
                    <div className="container">
                    <div className="zindex-up-7 text-right">
                        <div className="achievement-area">
                            <br />
                            <br />
                            <ScrollAnimation animateIn="fadeInLeft">
                                <h2 className="title color-light-accent" >We are committed to bringing the best in service, experience and technologies through our best in class team.</h2>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h3 className="title color-light-accent" >The Finstead Team</h3>
                            </ScrollAnimation>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    </div>
                </section>
            </Parallax>
        </div>
    )
}

export default AboutBlock1Paralax;