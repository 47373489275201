import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const ShortTestimonialSlider = () => {
    return (
        <>
        <ScrollAnimation animateIn="slideInUp">
          <img src="/img/banner/page-sep-1.png" style={{position: "relative", filter: "grayscale(100%)"}}></img>
        </ScrollAnimation>
        <section className="section-space-default-less30 bg-light-accent100">
          <div className="container">
            <div className="section-heading text-center heading-dark heading-layout1">
              <ScrollAnimation animateIn="fadeInUp">
              <h2 className='fs-section-header'>What Our Clients Say About Us</h2>
              </ScrollAnimation>
              {/* <p>Finstead can provide your business comprehensive advice and support across a number of verticals.</p>
              <p>We are committed to listening to your needs and working with you to achieve a fast outcome.</p> */}
            </div>
            <ScrollAnimation animateIn="fadeInRight" animateOut='fadeOutLeft'>
            <div className="rc-carousel nav-control-middle3" 
              data-loop="true" data-items={1} data-margin={0} data-autoplay="true" 
              data-autoplay-timeout={5000} data-smart-speed={3000} data-dots="false" 
              data-nav="false" data-nav-speed="false" data-r-x-small={1} 
              data-r-x-small-nav="false" data-r-x-small-dots="false" data-r-x-medium={2} 
              data-r-x-medium-nav="false" data-r-x-medium-dots="false" data-r-small={1} 
              data-r-small-nav="false" data-r-small-dots="false" data-r-medium={4} 
              data-r-medium-nav="false" data-r-medium-dots="false" data-r-large={4} 
              data-r-large-nav="false" data-r-large-dots="false">
                
                <div className="team-layout4 margin-b-30r bg-light-primary100" style={{width: "267px", margin: "auto auto"}}>
                  <div className="item-img" style={{maxHeight: "250px", height: "280px", filter: "grayscale(100%)"}}>
                    <img src={`/img/banner/darren.png`} alt="team" className="img-fluid width-100" style={{height: "100%", }} />
                    <div className='testimonial-hover'>Can't recommend Steve &amp; the team from Finstead enough. They just get small business &amp; the challenges it encompasses.I've have referred many SME's to Steve. Hes my go to guy! Simple - No Fuss &amp; Gets it done!</div>
                  </div>
                  <div className="item-content">
                    <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                      <a href="#">Darren</a>
                    </h3>
                    <div className="sub-title title-regular">Owner</div>
                    {/* <div className="sub-title title-regular"></div> */}
                  </div>
                </div>

                <div className="team-layout4 margin-b-30r bg-light-primary100" style={{width: "267px", margin: "auto auto"}}>
                  <div className="item-img" style={{maxHeight: "250px"}}>
                    <img src={`/img/banner/olivia.png`} alt="team" className="img-fluid width-100" style={{minHeight: "270px", objectPosition: "cover", filter: "grayscale(100%)"}} />
                    <div className='testimonial-hover'>I highly recommend the Finstead team to all my clients who require asset finance. Their professional and genuine approach is refreshing and I feel confident they will get the best deals in the market. Definitely recommend the Finstead team.</div>
                  </div>
                  <div className="item-content">
                    <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none" >
                      <a href="single-service1.html">Olivia</a>
                    </h3>
                    <div className="sub-title title-regular">Olivia - Principal</div>
                  </div>
                </div>

                <div className="team-layout4 margin-b-30r bg-light-primary100" style={{width: "267px", margin: "auto auto"}}>
                  <div className="item-img" style={{maxHeight: "250px"}}>
                    <img src={`https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Pramesh.jpg`} alt="team" className="img-fluid width-100" style={{filter: "grayscale(100%)"}}/>
                    <div className='testimonial-hover'>The guys at Finstead are the only place I would go or recommend friends or clients go for any type of business loan. They always deliver appropriate and speedy results. Steve is the man with all the answers for your business finance needs!</div>
                  </div>
                  <div className="item-content">
                    <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                      <a href="#">Pramesh</a>
                    </h3>
                    <div className="sub-title title-regular">Director</div>
                    {/* <div className="sub-title title-regular">The guys at Finstead are the only place I would go or recommend friends or clients go for any type of business loan. They always deliver appropriate and speedy results. Steve is the man with all the answers for your business finance needs!</div> */}
                  </div>
                </div>

                <div className="team-layout4 margin-b-30r bg-light-primary100" style={{width: "267px", margin: "auto auto"}}>
                  <div className="item-img" style={{maxHeight: "250px"}}>
                    <img src={`https://cdn.finstead.com.au/capital-site-assets/assets/images/testimonial+images/Dom.jpg`} alt="team" className="img-fluid width-100"  style={{filter: "grayscale(100%)"}}/>
                    <div className='testimonial-hover'>I was very impressed with both the great customer service and attention to detail that I received from Lucinda at Finstead. She was charming and great to work with. She made the whole process very easy. I would recommend her to anyone.</div>
                  </div>
                  <div className="item-content">
                    <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                      <a href="#">Dominique</a>
                    </h3>
                    <div className="sub-title title-regular">Owner</div>
                    {/* <div className="sub-title title-regular">I was very impressed with both the great customer service and attention to detail that I received from Lucinda at Finstead. She was charming and great to work with. She made the whole process very easy. I would recommend her to anyone.</div> */}
                  </div>
                </div>

                <div className="team-layout4 margin-b-30r bg-light-primary100" style={{width: "267px", margin: "auto auto"}}>
                  <div className="item-img" style={{maxHeight: "250px"}}>
                    <img src={`/img/testimonials/Faizal.jpg`} alt="team" className="img-fluid width-100"  style={{filter: "grayscale(100%)"}}/>
                    <div className='testimonial-hover'>The Finstead team and Lucinda represents among the highest levels of customer service I have experienced. Information was accurate, responses to queries were turned around very fast. </div>
                  </div>
                  <div className="item-content">
                    <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                      <a href="#">Faizal</a>
                    </h3>
                    <div className="sub-title title-regular">Mortgage Broker
                    </div>
                    {/* <div className="sub-title title-regular">I was very impressed with both the great customer service and attention to detail that I received from Lucinda at Finstead. She was charming and great to work with. She made the whole process very easy. I would recommend her to anyone.</div> */}
                  </div>
                </div>

              </div>
            </ScrollAnimation>

          </div>
        </section>
        </>
    )
}

export default ShortTestimonialSlider;