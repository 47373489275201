import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';


const ServicesShowcase = () => {
    return (
        <section className="section-space-default-less38 bg-light-primary100">
            <div className="container">
                <div className="section-heading text-center heading-dark heading-layout1">
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2 className='fs-section-header'>Our Services</h2>
                    </ScrollAnimation>
                </div>
                <div className="row">


                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className='row'>
                            <div className='col-xs-12 xol-sm-12 col-md-3 mb-4 text-center'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div style={{width: "60px", maxHeight: "80px"}}>
                                        <img src="/icons/asset_finance.png" style={{maxWidth: "60px", maxWidth: "60px"}}/>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-xs-12 xol-sm-12 col-md-9'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h4 className="title title-medium size-xs color-dark-accent hover-color-accent">
                                    <a href="/services/asset-finance">Asset Finance</a>
                                    </h4>
                                    <p className='size-xs'>We have the most experienced team of asset finance advisors in Australia, from yellow equipment, cars, trucks, boats and even aircraft. Leverage our buying power and credibility, with over 150 lenders.</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>  
                
                
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className='row'>
                            <div className='col-xs-12 xol-sm-12 col-md-3 mb-4 text-center'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div style={{width: "60px", maxHeight: "80px"}}>
                                        <img srcset="/icons/commercial_property.png 2x" style={{width: "60px", maxWidth: "60px"}}/>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-xs-12 xol-sm-12 col-md-9'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h4 className="title title-medium size-xs color-dark-accent hover-color-accent">
                                    <a href="/services/commercial-property">Commercial Property</a>
                                    </h4>
                                    <p className='size-xs'>Whether it’s buying, refinancing, releasing equity or a bridging solution. We have over 40 specialist lenders we work with to attain the best and fastest outcome. From private equity to top tier banks.</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>      
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className='row'>
                            <div className='col-xs-12 xol-sm-12 col-md-3 mb-4 text-center'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div style={{width: "60px", maxHeight: "80px"}}>
                                        <img srcset="/icons/working_capital.png 2x" style={{width: "60px", maxWidth: "60px"}}/>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-xs-12 xol-sm-12 col-md-9'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h4 className="title title-medium size-xs color-dark-accent hover-color-accent">
                                    <a href="/services/business-loans/">Working Capital</a>
                                    </h4>
                                    <p className='size-xs'>Debtor finance, supply chain solutions or just general purpose funds for day to day to running of the business. We have the latest solutions from bank and non bank lenders, with over 36 solutions.</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className='row'>
                            <div className='col-xs-12 xol-sm-12 col-md-3 mb-4 text-center'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div style={{width: "60px", maxHeight: "80px"}}>
                                        <img srcset="/icons/business_aquisition.png 2x" style={{width: "60px", maxWidth: "60px"}}/>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-xs-12 xol-sm-12 col-md-9'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h4 className="title title-medium size-xs color-dark-accent hover-color-accent">
                                    <a href="/services/business-loans/">Business Acquisition</a>
                                    </h4>
                                    <p className='size-xs'>We can assist with valuing, structuring and negotiating the purchase. We have a range of well priced solutions. With a team who are well versed in the science of mergers and acquisitions.</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className='row'>
                            <div className='col-xs-12 xol-sm-12 col-md-3 mb-4 text-center'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div style={{width: "60px", maxHeight: "80px"}}>
                                        <img srcset="/icons/trade_and_invoice_finance.png 2x" style={{width: "60px", maxWidth: "60px"}}/>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-xs-12 xol-sm-12 col-md-9'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h4 className="title title-medium size-xs color-dark-accent hover-color-accent">
                                    <a href="#">Trade and Invoice Finance</a>
                                    </h4>
                                    <p className='size-xs'>Fast and simple solutions from Australia’s most innovating trade finance lenders. If you’re importing consumables, technical equipment or even large mining equipment, we’ve seen it and have delivered great outcomes.</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className='row'>
                            <div className='col-xs-12 xol-sm-12 col-md-3 mb-4 text-center'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div style={{width: "60px", maxHeight: "80px"}}>
                                        <img srcset="/icons/refinancing.png 2x" width="60px" height="auto" style={{width: "60px", maxWidth: "60px"}}/>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-xs-12 xol-sm-12 col-md-9'>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h4 className="title title-medium size-xs color-dark-accent hover-color-accent">
                                    <a href="/services/business-loans/">Refinancing</a>
                                    </h4>
                                    <p className='size-xs'>Bank and non bank lenders have tightened credit, we have a range of capital sources to refinance term loans coming to their conclusion. Whether a business loan, asset finance or commercial property.</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default ServicesShowcase;