import React from 'react';
import HomePage from 'paths/home';
import Layout from 'components/layout';
import FsAnalytics from 'components/fs_analytics';


function App() {
    return (
        <Layout>
            {/* Finstead Analytics */}
            <FsAnalytics />
            <HomePage />
        </Layout>
    )
}

export default App;
