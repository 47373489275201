import React from "react";
import Slider1 from "components/slider_1";
import CTABar1 from "components/cta_bar_1";
import ServicesShowcase from "components/service_showcase";
import TestimonialSlider from "components/testimonial_slider";
import TeamShowcaseSlider from "components/team_showcase_slider";
import AssetFinancingProcessSteps from "components/asset_financing_process_steps";
import BrandLogosSlider from "components/brand_logos_slider";
import AboutBlock1Paralax from "components/about_block_1_paralax";
import AboutBlock3 from "components/about_block_3";
import ShortTestimonialSlider from "components/short_testimonial_slider";
import LenderLogosSlider from "components/lender_logos_slider";
import KG2Analytics from "components/kg2_analytics";

const HomePage = () => {
  return (
    <>
      {/* HERO BANNER */}
      <Slider1 />

      {/* KG2 Analytics */}
      {/* <KG2Analytics /> */}

      {/* BrandSlider */}
      {/* <BrandLogosSlider /> */}

      {/* CALL-US CTA Bar */}
      <CTABar1 />

      {/* SERVICES SHOWCASE */}
      <ServicesShowcase />

      <AboutBlock3 />

      <AboutBlock1Paralax />

      {/* TEAM SHOWCASE */}
      {/* <TeamShowcaseSlider /> */}
      <ShortTestimonialSlider />
    </>
  );
};

export default HomePage;
