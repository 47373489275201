import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';


const LenderLogosSlider = () => {
    return (
        <section className="section-space-brand bg-light-primary100">
            <div className="container">
                {/* <div className='row'>

                    <div className='col-4 text-center'>
                        <img style={{maxHeight: "40px", filter: "grayscale(100%)"}} src="https://cdn.finstead.com.au/mozo_logo.png" alt="Mozo" />
                    </div>
                    <div className='col-4 text-center'>
                        <img style={{maxHeight: "60px", filter: "grayscale(100%)"}} src="https://popbusiness.com.au/wp-content/uploads/2019/02/Artboard-10-copy@2x.png" alt="brand" />
                    </div>
                    <div className='col-4 text-center'>
                        <img style={{maxHeight: "30px", marginTop: "10px", filter: "grayscale(100%)"}} src="https://cdn.finstead.com.au/affiliates-finstead-com-au-logos/finsura/finsura-logo-dark-transparent.png" alt="brand" />
                    </div>
                </div> */}

            <div className="section-heading text-center heading-dark heading-layout1">
              <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutDown">
                <h2 className='fs-section-header'>Our Lenders</h2>
              </ScrollAnimation>
              </div>
                <br/>
                <br/>
                

                <div className="rc-carousel nav-control-middle2" 
                    data-loop="true" 
                    data-items={5} 
                    data-margin={20} 
                    data-autoplay="true" 
                    data-autoplay-timeout={2500}
                    data-smart-speed={1000} data-dots="false" data-nav="true" data-nav-speed="false" data-r-x-small={4} 
                    data-r-x-small-nav="false" data-r-x-small-dots="false" data-r-x-medium={4} data-r-x-medium-nav="false" 
                    data-r-x-medium-dots="false" data-r-small={3} data-r-small-nav="false" data-r-small-dots="false" 
                    data-r-medium={9} data-r-medium-nav="false" data-r-medium-dots="false">
                        
                    
                    <div className="brand-box">
                        <img src="/img/lenders/ANZ.jpeg" alt="ANZ" />
                    </div>

                    <div className="brand-box">
                        <img src="/img/lenders/Westpac.jpeg" alt="Westpac" />
                    </div>

                    <div className="brand-box">
                        <img src="/img/lenders/Macquarie_Logo_2.png" alt="Macquarie_Logo_2" />
                    </div>

                    <div className="brand-box">
                        <img src="/img/lenders/CBA.jpeg" alt="CBA" />
                    </div>

                    <div className="brand-box">
                        <img src="/img/lenders/Capital-Financ.jpeg" alt="Capital-Financ" />
                    </div>

                    <div className="brand-box">
                        <img src="/img/lenders/Liberty_Logo_2.png" alt="Liberty_Logo_2" />
                    </div>

                    <div className="brand-box">
                        <img src="/img/lenders/BOQ.jpeg" alt="BOQ" />
                    </div>

                    <div className="brand-box">
                        <img src="/img/lenders/Pepper_Money_Logo_2.png" alt="Pepper_Money_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/ACL_Logo_2.png" alt="ACL_Logo_2" />
                    </div>
                    </div>
                    <br />
                    <div className="rc-carousel nav-control-middle2" 
                        data-loop="true" 
                        data-items={5} 
                        data-margin={20} 
                        data-autoplay="true" 
                        data-autoplay-timeout={2500} 
                        data-smart-speed={2000}
                        data-dots="false" data-nav="true" data-nav-speed="false" data-r-x-small={4} 
                        data-r-x-small-nav="false" data-r-x-small-dots="false" data-r-x-medium={4} data-r-x-medium-nav="false" 
                        data-r-x-medium-dots="false" data-r-small={3} data-r-small-nav="false" data-r-small-dots="false" 
                        data-r-medium={9} data-r-medium-nav="false" data-r-medium-dots="false">

                    <div className="brand-box">
                        <img src="/img/lenders/AFS_Logo_2.png" alt="AFS_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/AMMF_Logo_2.png" alt="AMMF_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/Metro_Finance_Logo_2.png" alt="Metro_Finance_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/Money_3_Logo_2.png" alt="Money_3_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/Morris_Finance_Logo_2.png" alt="Morris_Finance_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/Now_Finance_Logo_2.png" alt="Now_Finance_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/RACV_Logo_2.png" alt="RACV_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/Selfco_Logo_2.png" alt="Selfco_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/Wisr_Logo_2.png" alt="Wisr_Logo_2" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/angle-finance.png" alt="angle-finance" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/bfs_new_logo.png" alt="bfs_new_logo" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/finance-one-logo.jpeg" alt="finance-one-logo" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/fmc_new_logo.png" alt="fmc_new_logo" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/greenlight.png" alt="greenlight" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/grow.png" alt="grow" />
                    </div>
                    <div className="brand-box">
                        <img src="/img/lenders/plenti_logo.jpeg" alt="plenti_logo" />
                    </div>

                </div>
            </div>
            <br/>
            <br/>
        </section>
    )
}

export default LenderLogosSlider;