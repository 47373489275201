import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const AssetFinancingProcessSteps = () => {
    return (
        <section className="section-space-default-less38 bg-light-primary">
          <div className="container">
            <div className="section-heading text-center heading-dark heading-layout1">
              <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutLeft">
                <h2 className='fs-section-header' style={{marginBottom: "15px"}}>Simple &amp; Efficient Application Process</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutRight">
                <p>We will take you through a simple and formalised process tailored to your needs</p>
              </ScrollAnimation>
            </div>
            <div className="row">
              <div className="single-item col-sm-6  col-lg-3">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutDown">
                  <div className="process-layout1">
                    <div className="item-icon-wrap">
                      <div className="item-icon bg-accent">
                        <i className="fas fa-tasks" />
                      </div>
                    </div>
                    <h3 className="title title-regular color-primary hover-color-accent">Requirement</h3>
                    <p>Provide your details and we will work with you to identify the best product to meet your specific needs.</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="single-item col-sm-6 col-lg-3">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutDown" delay={250}>
                  <div className="process-layout1">
                    <div className="item-icon-wrap">
                      <div className="item-icon bg-accent">
                        <i className="fas fa-check" />
                      </div>
                    </div>
                    <h3 className="title title-regular color-primary hover-color-accent">Engagement</h3>
                    <p>Your personal finance professional will reach out to discuss the options available and get your loan approved.</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="single-item col-sm-6  col-lg-3">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutDown" delay={500}>
                <div className="process-layout1">
                  <div className="item-icon-wrap">
                    <div className="item-icon bg-accent">
                      <i className="fas fa-dollar-sign" />
                    </div>
                  </div>
                  <h3 className="title title-regular color-primary hover-color-accent">Settlement</h3>
                  <p>We manage the entire process from application to disbursement of funds so you can focus on your business.</p>
                </div>
                </ScrollAnimation>
              </div>
              <div className="single-item col-sm-6  col-lg-3">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutDown" delay={750}>
                  <div className="process-layout1">
                    <div className="item-icon-wrap">
                      <div className="item-icon bg-accent">
                        <i className="fas fa-handshake" />
                      </div>
                    </div>
                    <h3 className="title title-regular color-primary">Commitment</h3>
                    <p>You have a trusted business partner that will assist you with all of your funding needs as your business grows.</p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
    )
}

export default AssetFinancingProcessSteps;