import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import LargeHeroBanner from 'components/large_hero_banner';

const Slider1 = () => {
    return (
        <div id="header-area-space" className="slider-area bg-light-primary">
            <LargeHeroBanner bgImage={"/img/slider/slider1-1.jpg"}>
                <h2>Your <strong>Partners</strong></h2>
                <h2>In Business And <strong>Finance</strong></h2>
                <br />
                <p className='lead'>We're here to help you grow your business</p>
                <a href="/contact" className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2">Enquire Now<i className="fas fa-angle-right" /></a>
            </LargeHeroBanner>
        </div>
    )
}

export default Slider1;